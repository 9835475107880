import React, { useContext, useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { sidebarData } from "../constraints/constants";
import { BiLogOutCircle } from "react-icons/bi";
import { AiOutlineGlobal } from "react-icons/ai";
import AuthContext from "../context/AuthContext";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import { sidebarCasinoList, DepositWithrawDataArray } from "../Utils/constants";
import Accordion from "react-bootstrap/Accordion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { isEmpty, toLower } from "lodash";
import { useNavigate } from "react-router-dom";

import PromotionsIcon from "../assets/images/icon-set/utility-type/icon-gift.svg";
import iconReferral from "../assets/images/icon-set/utility-type/icon-group.svg";
import iconVip from "../assets/images/icon-set/utility-type/icon-crown.svg";
import iconAffiliate from "../assets/images/icon-set/utility-type/icon-affiliate.svg";
import iconAmbassador from "../assets/images/icon-set/utility-type/icon-affiliate.svg";
import appdownload from "../assets/images/icon-set/utility-type/icon-download.svg";
import iconphone from "../assets/images/icon-set/utility-type/icon-phone.svg";
import iconpersoninfo from "../assets/images/icon-set/utility-type/icon-person-info.svg";
import iconopenbook from "../assets/images/icon-set/utility-type/icon-open-book.svg";
import iconochatbubble from "../assets/images/icon-set/utility-type/icon-chat-bubble.svg";
import iconlivechat from "../assets/images/icon-set/utility-type/icon-livechat.svg";
import iconhome from "../assets/images/icon-set/utility-type/icon-home.svg";

const MenuSidebarNew = () => {
  let {
    logoutUser,
    setShowSidebar,
    lang,
    user,
    setEn,
    setBr,
    launchEGTCasino,
    launchCasino,
    withoutLogin,
    showSidebar,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const setFunc = (type) => {
    setData(
      DepositWithrawDataArray?.find((res) => {
        {
          return res?.key == type;
        }
      })
    );
    setKey(type);
  };
  const [data, setData] = useState({});
  const [activeClass, SetActiveClass] = useState("");
  const [key, setKey] = useState("");
  useEffect(() => {
    // setData(
    //   DepositWithrawDataArray?.find((res) => {
    //     {
    //       return res?.key == "sports";
    //     }
    //   })
    // );

    window.addEventListener("scroll", () => {
      if (window.scrollY > 180) {
        SetActiveClass("gamefixed");
      } else {
        SetActiveClass("");
      }
    });
  }, []);
  const { t } = useTranslation();
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowSidebar(false);
      }}
    >
      <div>
        <div
          className={
            showSidebar ? "sidebar-wrapper slide-right lside" : "sidebar-wrapper"
          }
        >
          <div className="top-sidebar mb-3 ps-1 d-flex justify-content-between align-items-center">
            <div class="side-menu-toolkit-btn">
              {/* <img
                alt="live-support-icon"
                class="side-menu-toolkit-btn__img"
                src={iconlivechat}
                loading="lazy"
              /> */}
              {/* <div class="side-menu-toolkit-btn__text"> 24/7 Live Chat</div> */}
            </div>
            <div className="lh-top">
            <div class="side-menu-toolkit__btn side-menu-toolkit-btn">
              <img
                class="side-menu-toolkit-btn__img"
                alt="icon-home"
                src={iconhome}
                loading="lazy"
              />
            </div>
            <button
              className="bg-transparent border-0 p-0"
              onClick={() => setShowSidebar(false)}
            >
              <RxCross2 />
            </button>
            </div>
          </div>
          <ul className="casino-sidebar">
            <Accordion>
              {sidebarCasinoList?.map((item, index) => {
                return (
                  //   <li key={index}  onClick={() => {setFunc(item?.type); }}className={`${key == item?.type ? "active" : ""}`}>
                  //     <figure><img src={item?.icons} alt=""/></figure>
                  //     <a className="text-decoration-none"> <span>{lang == "bn" ? item?.titlebn : item?.title}</span></a>
                  // </li>

                  <Accordion.Item eventKey={index + 1}>
                    <Accordion.Header
                      onClick={() => {
                        setFunc(item?.type);
                      }}
                    >
                      {" "}
                      <img src={item?.icons} alt="" />{" "}
                      {lang == "bn" ? item?.titlebn : item?.title}
                    </Accordion.Header>
                    <Accordion.Body>
                      {key != "" && (
                        <div
                          className={
                            key == "exclusive"
                              ? "main-slider-div"
                              : "main-slider-div without-hotgame"
                          }
                        >
                          <div className="main-slider-inner-div ">
                            <div>
                              <h5>
                                {lang == "bn" ? data?.titlebn : data?.title}
                              </h5>
                            </div>

                            <ul className={""}>
                              {data?.array?.map((res) => {
                                return (
                                  <li>
                                    <div
                                      onClick={() => {
                                        if (!isEmpty(user)) {
                                          if (res?.gameTypeCheck == "sports") {
                                            window.open(res?.link, "_self");
                                          } else if (data?.key == "sports") {
                                            launchCasino({
                                              platForm: res?.platForm,
                                              gameType: res?.gameType,
                                              casinoType: res?.casinoType,
                                              isLobbyTrue: res?.isLobbyTrue,
                                            });
                                          } else if (
                                            res?.gameTypeCheck == "platForm"
                                          ) {
                                            launchCasino({
                                              platForm: res?.platForm,
                                              gameType: res?.gameType,
                                              casinoType: res?.casinoType,
                                              isLobbyTrue: res?.isLobbyTrue,
                                            });
                                          } else if (data?.key == "hotgame") {
                                            launchCasino({
                                              platForm: res?.platForm,
                                              gameType: res?.gameType,
                                              casinoType: res?.casinoType,
                                            });
                                          } else {
                                            if (!isEmpty(user)) {
                                              let subCategory =
                                                res?.platForm == "EVOLUTION"
                                                  ? "EVO"
                                                  : res?.platForm == "SEXYBCRT"
                                                  ? "SEXY"
                                                  : res?.platForm;
                                              navigate(
                                                "/casino?header=" +
                                                  toLower(res?.key) +
                                                  "&subCasino=" +
                                                  subCategory
                                              );
                                            } else {
                                              navigate("/login");
                                            }
                                          }
                                        } else {
                                          navigate("/login");
                                        }
                                      }}
                                    >
                                      {res?.image && (
                                        <span>
                                          <LazyLoadImage
                                            effect="opacity"
                                            alt={res?.img}
                                            src={res?.image}
                                          />
                                        </span>
                                      )}

                                      {key !== "exclusive" && (
                                        <p>{res?.title}</p>
                                      )}
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}{" "}
            </Accordion>
          </ul>

          <ul className="baji24-feature">
            {/* <li>
              <figure>
                <img src={PromotionsIcon} alt="" />
              </figure>
              <Link to="/promotions" className="text-decoration-none">
                {" "}
                <span> Promotions </span>
              </Link>
            </li> */}
            {/* <li>
              <figure>
                <img src={iconVip} alt="" />
              </figure>
              <Link to="#" className="text-decoration-none">
                {" "}
                <span> VIP Club</span>
              </Link>
            </li> */}
            {/* <li>
              <figure>
                <img src={iconReferral} alt="" />
              </figure>
              <Link to="/refer" className="text-decoration-none">
                {" "}
                <span> Referral Program </span>
              </Link>
            </li> */}
            {/* <li>
              <figure>
                <img src={iconAffiliate} alt="" />
              </figure>
              <Link to="/affiliate" className="text-decoration-none">
                {" "}
                <span> Affiliate</span>
              </Link>
            </li>
            <li>
              <figure>
                <img src={iconAmbassador} alt="" />
              </figure>
              <Link to="#" className="text-decoration-none">
                {" "}
                <span> Brand Ambassadors </span>
              </Link>
            </li> */}
            <li>
              <figure>
                <img src={appdownload} alt="" />
              </figure>
              <Link to="#" className="text-decoration-none">
                {" "}
                <span> App Download </span>
              </Link>
            </li>
          </ul>
          <ul className="baji24-help">
            <li>
              <figure>
                <img src={iconphone} alt="" />
              </figure>
              <Link to="#" className="text-decoration-none">
                {" "}
                <span> Contact Us </span>
              </Link>
            </li>
            <li>
              <figure>
                <img src={iconopenbook} alt="" />
              </figure>
              <Link to="#" className="text-decoration-none">
                {" "}
                <span> New Member Guide</span>
              </Link>
            </li>
            <li>
              <figure>
                <img src={iconpersoninfo} alt="" />
              </figure>
              <Link to="#" className="text-decoration-none">
                {" "}
                <span> About baji24</span>
              </Link>
            </li>
            {/* <li>
              <figure>
                <img src={iconochatbubble} alt="" />
              </figure>
              <Link to="/refer" className="text-decoration-none">
                {" "}
                <span> BJ Forum</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default MenuSidebarNew;
