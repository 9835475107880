import React from "react";
import { AiOutlineAudio } from "react-icons/ai";
import { HiOutlineSpeakerphone } from "react-icons/hi";
const CurrentNews = ({ message, setAnnouncement }) => {
  return (
    <>
      <div
        onClick={() => {
          if (message?.length > 0) {
            setAnnouncement(true);
          }
        }}
        className="marquee-notification d-flex"
      >
        <div className="audio-trakcer">
          <img src="../assets/images/icon-speaker.svg" style={{stroke:`#388ecc`}}></img>
        </div>
        {message?.length > 0 && (
          <marquee direction="left">
            {message?.map((res) => {
              return <strong style={{color:`#fff`}}>{res?.message}</strong>;
            })}
          </marquee>
        )}
      </div>
    </>
  );
};

export default CurrentNews;
