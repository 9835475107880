import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams, useNavigate } from "react-router-dom";
// import {decode as base64_decode, encode as base64_encode} from 'base-64';
import "../Spin.css";
import SpinWheel from "./SpinWheel/SpinWheel";

function FreeSpin() {
  const navigate = useNavigate();
  const [loggedInData, setLoggedInData] = useState(null);
  const [error, setError] = useState(null);

  const closeFreeSpin = () => {
    navigate("/");
  };
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <div className="App">
      <div id="cricket-page-wrapper" className="sport-page-wrapper">
        <div
          className="download-bar dark-theme"
          // style={{ position: "relative" }}
        >
          <img  src={
                process.env.REACT_APP_IMAGE_URL +
                "/assets/images/logo.png"
              } alt="download" class="mr-2 c-radis" />
          {/* <div className="mr-1 col text-center">
            <b>WELCOME FREE SPIN</b>
          </div> */}
          <span
            class="dark-theme_btn"
            style={{ cursor: "pointer" }}
            onClick={closeFreeSpin}
          >
            <img
              width="15"
              height="15"
              src={
                process.env.REACT_APP_IMAGE_URL +
                "/assets/images/cross-black.svg"
              }
              alt="download"
              className="mr-2 crose-icon"
            />
          </span>
        </div>
        <div className="iframe-container">
          <SpinWheel />
        </div>
      </div>
    </div>
  );
}

export default FreeSpin;
