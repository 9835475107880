import React, { useContext, useEffect, useRef,useState } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";
import rightArrow from "../assets/images/right-arrow.png";
import { Controller, useForm } from "react-hook-form";
import { Form, Button, InputGroup } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import { AiFillEye,AiFillEyeInvisible } from "react-icons/ai";
import { toast } from "react-toastify";
import { apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { pick, isEmpty } from "lodash";
import jwt_decode from "jwt-decode";
import welcomebn from "../assets/images/login-img.png";
import iconhome from "../assets/images/icon-set/utility-type/icon-home.svg";

const Login = () => {
  const { sendOTP,setBr, lang, setEn ,setUser,visiterId} = useContext(AuthContext);
  const { t } = useTranslation();
  const {
    register,
    unregister,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    shouldFocusError: true,
    defaultValues: {
      countryCode: 91,
      uniqueId: Math.random() * 10000,
    },
  });
  const inputRef = useRef(null);
  const navigate = useNavigate();
  let location = useLocation();
  const [isLoader, setLoader] = useState(false);
  let onSubmit = async (body) => {
    
    setLoader(true)
    body.visiterId=visiterId||"";
    const { status, data } = await apiPost(
      apiPath.loginUser,
      pick(body, ["username", "password","uniqueId","visiterId"])
    );
    if (status === 200) {
      if (data.success) {
        
        setTimeout(() => {
          navigate("/");
          window.location.reload();
        }, 500);
        setLoader(false);
        localStorage.setItem("token", data.results?.token);
        localStorage.setItem("refresh_token", data.results?.refresh_token);
        setUser(jwt_decode(data?.results?.token));
        navigate("/");
        
      } else {
        setLoader(false);
        toast.error(data?.message);
       
      }
    } else {
      setLoader(false);
      toast.error(data?.message);
      
    }
  };
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword =(e)=>{
    e.preventDefault()
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }
 const [getValidateCode, setValidateCode] = useState("");

  const canvasRef = useRef(null);
  const changeCode = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.font = "bold 120px sans-serif";
    const code = Math.floor(1000 + Math.random() * 9000);
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillText(code, 10, 115);
    setValidateCode(code);
  };
  useEffect(() => {
    changeCode();
    
  }, []);
  return (
    <>
      <div className="">
       
        <div className="login-form active" >
        <div className="back-header menu-header login-page-header">
         
        <Link to="/">
            <img src={logo} alt="" />
            </Link>
        
          <Link to="/"><img src={iconhome} style={{height:`25px`}}></img></Link>
          
        
        </div>
        <img src={welcomebn} style={{width:`100%`}}></img>
         {/* <ul className="login-singup-tab">
          <li className={location.pathname=="/login"?"active":""}>  
            <Link to="/login"> Log in  </Link>   
              
          </li>
          <li className={location.pathname=="/register"?"active":""}>     
          <Link to="/register" > Sign Up</Link>
          </li>
         </ul> */}
        <div className="reg-data slide-left">
        
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2">
              <Form.Group className="d-flex login-data-d">
                  <Form.Label>{t("Username")}</Form.Label>
                  <Form.Control
              type="text"
              
           
              {...register("username", {
                  required: "Please enter Username",
              })}
              placeholder="Please Enter Username"
          />
          
      </Form.Group>
      {errors.username && errors.username?.message && (
            <div className="text-danger">
              {errors.username.message}
            </div>
          )}
              <Form.Group className="d-flex login-data-d">
                <Form.Label> Password</Form.Label>
                    <div style={{ position:'relative',width:`100%`}}>
                    <Form.Control
                       type={passwordType ?passwordType:"password"}
                      placeholder="Enter Password"
                     
                      {...register("password", {
                        required: "Please enter password",
                        minLength: {
                          value: 4,
                          message:
                            "Password should contain atleast 8 characters",
                        },
                        maxLength: {
                          value: 15,
                          message:
                            "Password should contain maximum 16 characters",
                        },
                      
                      })}
                    />
                      <div className="input-group-btn" style={{position:'absolute', right:'0', top:'-10', bottom:'0px'}}>
                        <span className="btn btn-outline-primary" onClick={togglePassword} style={{backgroundColor:'transparent', border:'0',padding:'8px', paddingTop:'3px', height:'72%'}}>
                          {passwordType==="password"?<AiFillEyeInvisible/> :  <AiFillEye/>}
                        </span>
                      </div>
                    </div>
                    
              </Form.Group>
              {errors.password && errors.password.message && (
                      <div className="text-danger">
                        {errors.password.message}
                      </div>
                    )}
            </div>
            <dd id="validCodeErrorClass" style={{ display: "block",marginTop: `1px`,
    padding: `0` }} className="d-flex  login-data-d">
        <Form.Group className="form-group d-flex login-data-d">
        <Form.Label className="">{"Verification code"}</Form.Label>
              <input
                type="number"
                keyboardType="numeric"
                autocomplete="off"
                maxLength="4"
                className={"form-control"}
                {...register("validateCode", {
                  required: "Please enter validate code",
                  validate: {
                    validate: (value) =>
                      parseInt(value) === parseInt(getValidateCode) ||
                      "Invalid validate code",
                  },
                })}
                onChange={(e) => {
                  if (e.target.value.length == 4) {
                    e.target.blur();
                    unregister("validateCode", { keepValue: true });
                  }
                }}
               
                id="validCode"
                placeholder="Validation Code"
             
              />
             
              <canvas
                ref={canvasRef}
                onClick={changeCode}
                className="inputcanvas"
                id="authenticateImage"
              />
</Form.Group>
              
            </dd>
            {errors.validateCode && errors.validateCode.message && (
                <label className="text-danger text-left">
                  {errors.validateCode.message}
                </label>
              )}
            <div  class="login-info-box">
            <div class="forgetpassword-buttn"><Link to="/forgot-password">Forgot password?</Link></div>
            </div>

            <button type="submit" className="submit-btn" disabled={isLoader}>
              <span> {isLoader ? "Loading..." :t("Login")}</span>
            </button>
            {/* <div className="term-condtion">
              <p>
                {t("Do_not_have_an_account")}
                <Link to="/register">{t("Sign_up")}</Link>
              </p>
            </div> */}
          </Form>
        </div>
      </div>
      </div>
      
    </>
  );
};

export default Login;
