import hotIcon from "../assets/images/icon-set/icon-exclusive.png";
import sportsIcon from "../assets/images/icon-set/icon-sport.png";
import casinoIcon from "../assets/images/icon-set/icon-casino.png";
import slotIcon from "../assets/images/icon-set/icon-slot.png";
import TableIcon from "../assets/images/icon-set/icon-table.png";
import fishicon from "../assets/images/icon-set/icon-fish.png";
import crashIcon from "../assets/images/icon-set/icon-crash.png";
import lotteryIcon from "../assets/images/icon-set/icon-lottery.png";
import arcadeIcon from "../assets/images/icon-set/icon-arcade.png";


import sidebarhotIcon from "../assets/images/icon-set/inactive/icon-exclusive.png";
import sidebarsportIcon from "../assets/images/icon-set/inactive/icon-sport.png";
import sidebarcasinoIcon from "../assets/images/icon-set/inactive/icon-casino.png";
import sidebarslotIcon from "../assets/images/icon-set/inactive/icon-slot.png";
import sidebartableIcon from "../assets/images/icon-set/inactive/icon-table.png";
import sidebarfishIcon from "../assets/images/icon-set/inactive/icon-fish.png";
import sidebarcrashIcon from "../assets/images/icon-set/inactive/icon-crash.png";
import sidebararcadeIcon from "../assets/images/icon-set/inactive/icon-arcade.png";
import sidebarlotteryIcon from "../assets/images/icon-set/inactive/icon-lottery.png";


import withdrawIcon from "../assets/images/withdraw.png";
import DepositIcon from "../assets/images/money.png";

import Jili from "../assets/images/jili.png";

import iconExchange from "../assets/images/game-type/sport/icon-exchange.svg";
import iconSbo from "../assets/images/game-type/sport/icon-sbov2.svg";
import iconSportbook from "../assets/images/game-type/sport/icon-sportbook.svg";
import iconHorsebook from "../assets/images/game-type/sport/icon-horsebook.svg";
import iconVirtual from "../assets/images/game-type/sport/icon-virtual.svg";
import iconkabaddi from "../assets/images/game-type/sport/icon-kabaddi.svg";
import iconsbtech from "../assets/images/game-type/sport/icon-sbtech.svg";
import iconpowerelevenv2 from "../assets/images/game-type/sport/icon-powerelevenv2.svg";
import iconawcme1sport from "../assets/images/game-type/sport/icon-awcme1sport.svg";
import iconcmd from "../assets/images/game-type/sport/icon-cmd.svg";
import velkieLogo from "../assets/images/exchange2.png";




import fc from "../assets/images/fc.png";
import jdb from "../assets/images/jdb.png";
import km from "../assets/images/km.png";
import ludo from "../assets/images/ludo.png";
import pg from "../assets/images/pg.png";
import pt from "../assets/images/pt.png";
import ps from "../assets/images/provider-ps.png";
import rt from "../assets/images/rt.png";
import sexy from "../assets/images/sexy.png";
import sg from "../assets/images/sg.png";
import venus from "../assets/images/venus.png";
import evo from "../assets/images/evo.png";
import sv from "../assets/images/sv.webp";
import pp from "../assets/images/pp.png";
import yl from "../assets/images/yl.png";
import yesbingo from "../assets/images/provider-awcmyesbingo.png";



// feature games
import koreanspeed from "../assets/images/features/korean-speed.png";
import superAce from "../assets/images/features/superace.png";
import moneyComing from "../assets/images/features/moneycoming.png";
import boxingking from "../assets/images/features/boxingking.png";
import superelements from "../assets/images/features/super-elements.png";
import fortunemoney from "../assets/images/features/fortune-money.png";
import wildbounty from "../assets/images/features/wild-bounty.png";
import treasure from "../assets/images/features/treasure.png";
import fieryseven from "../assets/images/features/fiery-seven.png";
import fruitynonanza from "../assets/images/features/fruity-nonanza.png";







import aeSexy from "../assets/images/aeSexy.webp";


import pakistanFlag from "../assets/images/flag-symbolism-Pakistan-design-Islamic.png";
import camodiaFlag from "../assets/images/Flag-Cambodia.png";
import philippinesFlag from "../assets/images/Flag_of_philippines.svg.jpg";
import brazilFlag from "../assets/images/Flag_of_Brazil.svg.png";
import indiaFlag from "../assets/images/Flag_of_India.svg.png";
import bangladeshFlag from "../assets/images/Flag_of_Bangladesh.svg.png";
// hotgame Image


import hotgame028 from "../assets/images/hot-casino/KM-TABLE-028.webp";
import hotgame035 from "../assets/images/hot-casino/JILI-TABLE-035.webp";
import hotgame030 from "../assets/images/hot-casino/JILI-TABLE-030.webp";
import hotgame060 from "../assets/images/hot-casino/KM-TABLE-060.webp";

import hotgame047 from "../assets/images/hot-casino/JILI-TABLE-047.webp";
import hotgame015 from "../assets/images/hot-casino/KM-TABLE-015.webp";
import hotgame021 from "../assets/images/hot-casino/KM-TABLE-021.webp";
import hotgame022 from "../assets/images/hot-casino/KM-TABLE-022.webp";
import pgdragon from "../assets/images/hot-casino/pgdragon.webp";


import btg from "../assets/images/btg.png";
import dragonsoft from "../assets/images/DRAGONSOFT.webp";
import biggaming from "../assets/images/BIGGAMING.webp";
import sabavrtual from "../assets/images/SABAVIRTUAL.webp";
import ezugi from "../assets/images/EZUGI.webp";
import superspade from "../assets/images/SUPERSPADE.webp";
import onetouch from "../assets/images/ONETOUCH.webp";
import spribe from "../assets/images/SPRIBE.webp";
import bombaylive from "../assets/images/BOMBAYLIVE.webp";
import royalgaming from "../assets/images/ROYALGAMING.webp";
import iconawcmbc from "../assets/images/icon-awcmbc.png";
import iconhotroad from "../assets/images/hotroad.png";
import aviator from "../assets/images/hot-casino/aviator.png";
import betgames from "../assets/images/BETGAMES.webp";
import casinolive from "../assets/images/casinolive.svg";
import casinoslot from "../assets/images/casinoslot.svg";
import casinoesports from "../assets/images/casinoesports.svg";
import casinofishing from "../assets/images/casinofishing.svg";
import casinoegame from "../assets/images/casinoegame.svg";
import casinotable from "../assets/images/casinotable.svg";
import casinovirtual from "../assets/images/casinovirtual.svg";
import casinolotto from "../assets/images/casinolotto.svg";
import casinobingo from "../assets/images/casinobingo.svg";
import casinocrash from "../assets/images/casinocrash.svg";
import casinogameshow from "../assets/images/casinogameshow.svg";
import fastspin from "../assets/images/FASTSPIN.webp";
import netent from "../assets/images/provider-netent.png";
import nlcLogo from "../assets/images/nl-yellow.svg";
import sv388 from "../assets/images/SV388.webp";

// hotgame Image
import hot1 from "../assets/images/hot-casino/exclusive-money-wheel.png";
import hot2 from "../assets/images/hot-casino/exclusive-fortune-gems.png";
import hot3 from "../assets/images/hot-casino/exclusive-divas-ace.png";
import hot4 from "../assets/images/hot-casino/exclusive-the-kings-ace.png";
import hot5 from "../assets/images/hot-casino/exclusive-fc-roma.png";
import hot6 from "../assets/images/hot-casino/exclusive-sicbo.png";
import hot7 from "../assets/images/hot-casino/exclusive-7updown.png";
import hot8 from "../assets/images/hot-casino/22001.png";

const obj = {
  user_status: {
    owner: "OW",
    sub_owner: "SOW",
    super_admin: "SUA",
    admin: "AD",
    sub_admin: "SAD",
    senior_super: "SSM",
    super_agent: "SA",
    agent: "AG",
    user: "CL",
  },
  user_next_status: {
    owner: "sub_owner",
    sub_owner: "super_admin",
    super_admin: "admin",
    admin: "sub_admin",
    sub_admin: "senior_super",
    senior_super: "super_agent",
    super_agent: "agent",
    agent: "user",
  },
  market_category: {
    1: "Market",
    3: "Bookmakers",
    5: "ManualOdds",
    10: "Win Toss",
    2: "Session",
    6: "Over by Over Session Market",
    7: "Player",
    9: "Wicket",
    11: "Bowler Session",
    8: "Last Digit ODD",
    4: "LineMarket",
    14: "Premium ODDs",
  },
  betCheckObj: {
    4: "Cricket",
    2: "Tennis",
    1: "Soccer",
    3: "Casino",
  },
  matchType: {
    cricket: "Cricket",
    tennis: "Tennis",
    soccer: "Soccer",
    casino: "Casino",
  },
};
export default obj;
export const priceFormat = (value) => {
  return new Intl.NumberFormat().format(value);
};

export const CountryFlagBanner = [
  { id: 1, banner: pakistanFlag,title:"Pakistan" },
  { id: 2, banner: camodiaFlag ,title:"camodia"},
  { id: 3, banner: philippinesFlag ,title:"philippines"},
  { id: 4, banner: brazilFlag ,title:"brazil"},
  { id: 5, banner: indiaFlag,title:"india" },
  { id: 6, banner: bangladeshFlag ,title:"bangladesh"},
];


export const SlotData = [
  
  {
    id: 2,
    icons: koreanspeed,
    title: "Koren Speed Baccarart NC",
    titlebn: "সুপার এস",
    gameTypeCheck: "platForm",
    gameType: "PT",
    platForm: "LIVE",
    casinoType: "PT-LIVE-096",

  },
  {
    id: 2,
    icons: superAce,
    title: "Super Ace",
    titlebn: "সুপার এস",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType: "JILI-SLOT-027",

  },{
    id: 2,
    icons: moneyComing,
    title: "Money Coming",
    titlebn: "টাকা আসছে",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType: "JILI-SLOT-029",

  },
  {
    id: 2,
    icons: boxingking,
    title: "Boxing King",
    titlebn: "ভাগ্য রত্ন",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JILI",
    casinoType: "JILI-SLOT-031",

  },
  {
    id: 2,
    icons: superelements,
    title: "Super Elements",
    titlebn: "জেডিবি",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "FC",
    casinoType:"FC-SLOT-033"
  },
  {
    id: 3,
    icons: fortunemoney,
    title: "FORTUNE MONEY BOOM",
    titlebn: "জিলি",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "FC",
    casinoType:"FC-SLOT-039"
  },
  {
    id: 4,
    icons: wildbounty,
    title: "Wild Bounty Showdown",
    titlebn: "পৃ",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "PG",
    casinoType:"PG-SLOT-105"
  },
  {
    id: 5,
    icons: treasure,
    title: "Treasures of Aztec",
    titlebn: "কোদাল",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "PG",
    casinoType:"PG-SLOT-058"
  },
  {
    id: 6,
    icons: fieryseven,
    title: "FierySevens",
    titlebn: "ইভো",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "SG",
    casinoType: "SG-SLOT-079",
    
  },
  {
    id: 2,
    icons: fruitynonanza,
    title: "Fruity Bonanza",
    titlebn: "ভাগ্য রত্ন",
    gameTypeCheck: "platForm",
    gameType: "SLOT",
    platForm: "JDB",
    casinoType: "JDB-SLOT-114",

  },
];


export const ProviderSliderData = [
  {
    id: 1,
    icons: aeSexy,
    title: "Sexy",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-002",
    islobbyTrue:true
  },
  {
    id: 2,
    icons: Jili,
    title: "JILI",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "JILI",
    gameType: "",
    casinoType: "",
   
  },
  {
    id: 3,
    icons: evo,
    title: "EVO",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-006",
    islobbyTrue:true
   
  },
  {
    id: 3,
    icons: jdb,
    title: "JDB",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "JDB",
    gameType: "",
    casinoType: "",
   
   
  },
  {
    id: 3,
    icons: fc,
    title: "Fa Chai",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "FC",
    gameType: "",
    casinoType: "",
   
   
  },
  {
    id: 3,
    icons: yesbingo,
    title: "Yellow Bat",
    titlebn: "এই সেক্সি",
    gameTypeCheck: "platForm",
    platForm: "YESBINGO",
    gameType: "",
    casinoType: "",
   
   
  },
];
export const sidebarCasinoList = [
  {
    id: 1,
    icons: sidebarhotIcon,
    title: "Exclusive",
    type: "exclusive",
    titlebn: "গরম খেল",
  },
  {
    id: 2,
    icons: sidebarsportIcon,
    title: "Sports",
    type: "sports",
    titlebn: "বিনিময়",
    position: "translate(5px, -7px)",
  },
  {
    id: 3,
    icons: sidebarcasinoIcon,
    title: "Casino",
    titlebn: "লাইভ ক্যাসিনো",
    type: "casino",
    position: "translate(-5px, 2px)",
  },
  {
    id: 4,
    icons: sidebarslotIcon,
    title: "Slots",
    titlebn: "স্লট",
    type: "slot",
    position: "translate(-1px, -7px)",
  },
  {
    id: 5,
    icons: sidebartableIcon,
    title: "Table",
    titlebn: "টেবিল",
    type: "table",
    position: "translate(-9px, 3px)",
  },
  {
    id: 8,
    icons: sidebarcrashIcon,
    title: "Crash",
    titlebn: "ফিসিং",
    type: "crash",
    position: "translate(-5px, 2px)",
  },
  {
    id: 7,
    icons: sidebarfishIcon,
    title: "Fishing",
    titlebn: "মাছ ধরা",
    type: "fish",
    position:'translate(-4px, -5px)',
  },
  
  {
    id: 9,
    icons: sidebararcadeIcon,
    title: "Arcade",
    type: "arcade",
    titlebn: "স্পোর্টসবুক",
  },

  {
    id:10,
    icons: sidebarlotteryIcon,
    title: "Virtual",
    titlebn: "খেলাধুলা",
    type: "virtual",
    position: "translate(-7px, 9px)",
  },
];

export const DepositWithrawData = [
  {
    id: 1,
    icons: hotIcon,
    title: "Exclusive",
    type: "exclusive",
    titlebn: "গরম খেল",
  },
  {
    id: 2,
    icons: sportsIcon,
    title: "Sports",
    type: "sports",
    titlebn: "বিনিময়",
    position: "translate(5px, -7px)",
  },
  {
    id: 3,
    icons: casinoIcon,
    title: "Casino",
    titlebn: "লাইভ ক্যাসিনো",
    type: "casino",
    position: "translate(-5px, 2px)",
  },
  {
    id: 4,
    icons: slotIcon,
    title: "Slots",
    titlebn: "স্লট",
    type: "slot",
    position: "translate(-1px, -7px)",
  },
  {
    id: 5,
    icons: TableIcon,
    title: "Table",
    titlebn: "টেবিল",
    type: "table",
    position: "translate(-9px, 3px)",
  },
  {
    id: 8,
    icons: crashIcon,
    title: "Crash",
    titlebn: "ফিসিং",
    type: "crash",
    position: "translate(-5px, 2px)",
  },
  {
    id: 7,
    icons: fishicon,
    title: "Fishing",
    titlebn: "মাছ ধরা",
    type: "fish",
    position:'translate(-4px, -5px)',
  },
  
 
  {
    id: 9,
    icons: arcadeIcon,
    title: "Arcade",
    type: "arcade",
    titlebn: "স্পোর্টসবুক",
  },

  {
    id:10,
    icons: lotteryIcon,
    title: "Lottery",
    titlebn: "খেলাধুলা",
    type: "virtual",
    position: "translate(-7px, 9px)",
  },
];
export const DepositWithrawDataArray = [
  {
    id: 1,
    title: "Exclusive",
    key: "exclusive",
    titlebn: "গরম খেল",
    array: [
      {
        id: 1,
        image: hot8,
        title: "Aviator",
        type: "Aviator",
        gameTypeCheck: "platForm",
        platForm: "SPRIBE",
        gameType: "EGAME",
        casinoType: "SPRIBE-EGAME-001",
      },
      {
        id: 1,
        image: hot1,
        title: "RollingIn Money",
        type: "rollinginmoney",
        gameTypeCheck: "platForm",
        platForm: "JDB",
        gameType: "SLOT",
        casinoType: "JDB-SLOT-035",
      },
      {
        id: 2,
        image: hot2,
        title: "Fortune Gems",
        type: "fortunegems",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-043",
      },
      {
        id: 3,
        image: hot3,
        title: "Royal Ace",
        type: "royalace",
        gameTypeCheck: "platForm",
        platForm: "YESBINGO",
        gameType: "SLOT",
        casinoType: "YesBingo-SLOT-022",
      },
      
      {
        id: 4,
        image: hot4,
        title: "Magic Ace",
        type: "magicace",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JDB-SLOT-124",
      },
      {
        id: 5,
        image: hot5,
        title: "GLORY OF ROME",
        type: "GLORYOFROME",
        gameTypeCheck: "platForm",
        platForm: "FC",
        gameType: "SLOT",
        casinoType: "FC-SLOT-019",
      },
      {
        id: 6,
        image: hot6,
        title: "Sicbo",
        type: "GLORYOFROME",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-015",
      },
      {
        id: 5,
        image: hot7,
        title: "7up7Down",
        type: "7up7Down",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-028",
      },
    ],
  },
  {
    id: 1,
    title: "Sports",
    key: "sports",
    titlebn: "বিনিময়",
    array: [
      {
        image: iconExchange,
        title: "Exchange 1",
        gameTypeCheck: "sports",
        link: `${process.env.REACT_APP_VELKIE_URL}bkash/${localStorage.getItem(
          "token"
        )}`,
      },
      {
        image: velkieLogo,
        title: "Excahnge 2",
        gameTypeCheck: "sports",
        link: `${process.env.REACT_APP_SKY_URL}bkash/${localStorage.getItem(
          "token"
        )}`,
      },
      {
        image: iconSportbook,
        title: "I-Sports",
        link: "",
        gameTypeCheck: "platForm",
        platForm: "SABA",
        gameType: "VIRTUAL",
        casinoType: "SABA-VIRTUAL-001",
      },
      {
        image: iconSbo,
        title: "SBO Sports",
        link: "",
        gameTypeCheck: "platForm",
        platForm: "SABA",
        gameType: "VIRTUAL",
        casinoType: "SABA-VIRTUAL-001",
      },
      {
        image: iconHorsebook,
        title: "Horcebook",
        link: "",
        gameTypeCheck: "platForm",
        platForm: "HORSEBOOK",
        gameType: "LIVE",
        casinoType: "HRB-LIVE-001",
      },
      {
        image: iconVirtual,
        title: "Virtual",
        link: "",
        gameTypeCheck: "platForm",
        platForm: "SABA",
        gameType: "VIRTUAL",
        casinoType: "SABA-VIRTUAL-001",
      },
      // {
      //   image: iconkabaddi,
      //   title: "Kabaddi",
      //   link: "",
      //   gameTypeCheck: "platForm",
      //   platForm: "SABA",
      //   gameType: "VIRTUAL",
      //   casinoType: "SABA-VIRTUAL-001",
      //   islobbyTrue: true,
      // },
      {
        image: iconsbtech,
        title: "Bti Sports",
        link: "",
        gameTypeCheck: "platForm",
        platForm: "SABA",
        gameType: "VIRTUAL",
        casinoType: "SABA-VIRTUAL-001",
      },
      {
        image: iconpowerelevenv2,
        title: "Fantasy",
        link: "",
        gameTypeCheck: "platForm",
        platForm: "SABA",
        gameType: "VIRTUAL",
        casinoType: "SABA-VIRTUAL-001",
      },
      {
        image: iconawcme1sport,
        title: "E1Sports",
        link: "",
        gameTypeCheck: "platForm",
        platForm: "E1SPORT",
        gameType: "ESPORTS",
        casinoType: "E1-ESPORTS-001",
      },
      {
        image: iconcmd,
        title: "CMD Sports",
        link: "",
        gameTypeCheck: "platForm",
        platForm: "SABA",
        gameType: "VIRTUAL",
        casinoType: "SABA-VIRTUAL-001",
      },
    ],
  },
  {
    id: 1,
    title: "Crash",
    key: "crash",
    titlebn: "গরম খেল",
    array: [
      {
        id: 1,
        image: aviator,
        title: "Aviator",
        type: "aviator",
        gameTypeCheck: "platForm",
        platForm: "SPRIBE",
        gameType: "EGAME",
        casinoType: "SPRIBE-EGAME-001",
      },
      {
        id: 1,
        image: hotgame030,
        title: "Go Rush",
        type: "Go Rush",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-030",
      },
      {
        id: 2,
        image: hotgame047,
        title: "Crash Bonus",
        type: "Crash Bonus",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-047",
      },
      {
        id: 3,
        image: hotgame035,
        title: "Limbo",
        type: "Limbo",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-035",
      },
      {
        id: 4,
        image: hotgame021,
        title: "Sugar Blast",
        type: "Sugar Blast",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "SLOT",
        casinoType: "KM-SLOT-001",
      },
      {
        id: 5,
        image: hotgame015,
        title: "Sicbo",
        type: "Sicbo",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-015",
      },

      {
        id: 6,
        image: hotgame021,
        title: "Matka",
        type: "Matka",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-021",
      },
      {
        id: 7,
        image: hotgame022,
        title: "Card Matka",
        type: "Card Matka",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-022",
      },
      {
        id: 8,
        image: hotgame028,
        title: "7 Up 7 Down",
        type: "7 Up 7 Down",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-028",
      },
      {
        id: 9,
        image: hotgame060,
        title: "Ludo",
        type: "Ludo",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-060",
      },
      {
        id: 10,
        image: pgdragon,
        title: "Dragon ",
        type: "Dragon Tiger",
        gameTypeCheck: "platForm",
        platForm: "PG",
        gameType: "SLOT",
        casinoType: "PG-SLOT-035",
      },
    ],
  },
  {
    id: 2,
    title: "Live Casino",
    key: "casino",
    titlebn: "লাইভ ক্যাসিনো",
    array: [
      {
        image: evo,
        title: "EVOLUTION",
        type: "evo",
        key: "live",
        // gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-006",
        isLobbyTrue: true,
      },
      {
        image: sexy,
        title: "SEXYBCRT",
        type: "sexy",
        key: "live",
        // gameTypeCheck: "platForm",
        platForm: "SEXYBCRT",
        gameType: "LIVE",
        casinoType: "MX-LIVE-001",
        isLobbyTrue: true,
      },
      {
        image: pp,
        title: "PRAGMATICPLAY",
        type: "pp",
        key: "live",
        platForm: "PP",
        gameType: "LIVE",
        casinoType: "PP-LIVE-001",
      },
      {
        image: pt,
        title: "PLAYTECH",
        type: "pt",
        key: "live",
        platForm: "PT",
      },
      // {
      //   image: Jili,
      //   title: "JILI",
      //   type: "jili",
      //   key: "live",
      //   platForm: "JILI",
      // },
      // {
      //   image: km,
      //   title: "KINGMAKER",
      //   type: "kingmaker",
      //   key: "live",
      //   gameType: "TABLE",
      //   platForm: "KINGMAKER",
      // },
      // {
      //   image: ludo,
      //   title: "LUDO",
      //   type: "ludo",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "KINGMAKER",
      //   gameType: "TABLE",
      //   casinoType: "KM-TABLE-060",
      // },
      // {
      //   image: iconHorsebook,
      //   title: "HOORSEBOOK",
      //   type: "horsebook",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "HORSEBOOK",
      //   gameType: "LIVE",
      //   casinoType: "HRB-LIVE-001",
      // },
      {
        image: iconhotroad,
        title: "HOTROAD",
        type: "hotroad",
        key: "live",
        // gameTypeCheck: "platForm",
        platForm: "HOTROAD",
        gameType: "LIVE",
        casinoType: "HOTROAD-LIVE-001",
        isLobbyTrue: true,
      },
      // {
      //   image: betgames,
      //   title: "BETGAMES",
      //   type: "bg",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "BG",
      //   gameType: "LIVE",
      //   casinoType: "BG-LIVE-001",
      //   isLobbyTrue: true,
      // },
      // {
      //   image: rt,
      //   title: "REDTIGER",
      //   type: "rt",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "RT",
      //   gameType: "SLOT",
      //   casinoType: "RT-SLOT-001",
      //   isLobbyTrue: true,
      // },
      // {
      //   image: sg,
      //   title: "SPADEGAMING",
      //   type: "spade",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "SPADE",
      //   gameType: "SLOT",
      //   casinoType: "SG-SLOT-001",
      //   isLobbyTrue: true,
      // },
      // {
      //   image: fc,
      //   title: "FC",
      //   type: "fc",
      //   key: "live",
      //   gameType: "LIVE",
      //   platForm: "FC",
      // },
      // {
      //   image: jdb,
      //   title: "JDB",
      //   type: "jdb",
      //   key: "live",
      //   gameType: "LIVE",
      //   platForm: "JDB",
      // },
      // {
      //   image: yl,
      //   title: "YL",
      //   type: "yl",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   gameType: "LIVE",
      //   platForm: "YL",
      // },

      // {
      //   image: dragonsoft,
      //   title: "DRAGONSOFT",
      //   type: "dragonsoft",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "DRAGOONSOFT",
      //   gameType: "",
      //   casinoType: "",
      // },
      
      

      // {
      //   image: yesbingo,
      //   title: "YESBINGO",
      //   type: "yesbingo",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "YESBINGO",
      // },

      // {
      //   image: fastspin,
      //   title: "FASTSPIN",
      //   type: "FastSpin",
      //   key: "live",

      //   gameType: "EGAME",
      //   platForm: "JDB",
      //   casinoType: "JDB-EGAME-018",
      // },
      // {
      //   image: casinoesports,
      //   title: "E1SPORT",
      //   type: "e1sports",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   gameType: "ESPORTS",
      //   platForm: "E1SPORT",
      //   casinoType: "E1-ESPORTS-001",
      // },
      {
        image: biggaming,
        title: "BIGGAMING",
        type: "biggaming",
        key: "live",
        // gameTypeCheck: "platForm",
        platForm: "BG",
        gameType: "LIVE",
        casinoType: "BG-LIVE-001",
        isLobbyTrue: true,
      },
      // {
      //   image: pg,
      //   title: "PGSOFT",
      //   type: "pg",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PG",
      // },

      // {
      //   image: sv388,
      //   title: "SV388",
      //   type: "sv",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "SV388",
      //   gameType: "LIVE",
      //   casinoType: "SV-LIVE-001",
      // },

      // {
      //   image: sabavrtual,
      //   title: "SABAVIRTUAL",
      //   type: "SABAVIRTUAL",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "SABA",
      //   gameType: "VIRTUAL",
      //   casinoType: "SABA-VIRTUAL-001",
      // },
      // { image: ezugi,
      //   title: "Ezugi",
      //   type: "ezugi",
      //   key: "live",
      //   gameType: "egtGame",
      //   platForm: "Ezugi",
      //   gameTypeNew: "",
      //   casinoType: "",
      // },
      // {
      //   image: superspade,
      //   title: "SUPERSPADE",
      //   type: "SUPERSPADE",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   gameType: "",
      //   platForm: "SPADE",
      //   casinoType: "",
      // },
      //  {
      //       image: evoplay,
      //       title: "EVOPLAY",
      //       type: "EVOPLAY",
      //       key: "live",
      //       gameType: "egtGame",
      //       platForm: "EVO",
      //       gameTypeNew: "",
      //       casinoType: "LIVE CASINO",
      //  },

      //  {
      //   image: onetouch,
      //   title: "ONETOUCH",
      //   type: "ONETOUCH",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "ONETOUCH",
      // },
      // {
      //   image: spribe,
      //   title: "SPRIBE",
      //   type: "SPRIBE",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "SPRIBE",
      //   gameType: "",
      //   casinoType: "",
      // },
      // {
      //   image: bombaylive,
      //   title: "SPRIBE",
      //   type: "SPRIBE",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "SPRIBE",
      // },
      // {
      //   image: royalgaming,
      //   title: "SPRIBE",
      //   type: "SPRIBE",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "SPRIBE",
      // },
    ],
  },
  {
    id: 2,
    title: "Type",
    key: "type",
    titlebn: "লাইভ ক্যাসিনো",
    array: [
      {
        image: iconHorsebook,
        title: "HIORSEBOOK",
        type: "horsebook",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "HORSEBOOK",
        gameType: "LIVE",
        casinoType: "HRB-LIVE-001",
      },
      {
        image: casinolive,
        title: "JILI",
        type: "jili",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "JILI",
      },
      {
        image: casinoslot,
        title: "KINGMAKER",
        type: "kingmaker",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
      },
      {
        image: casinoesports,
        title: "LUDO",
        type: "ludo",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-060",
      },
      {
        image: casinofishing,
        title: "EVOLUTION",
        type: "evo",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "",
        casinoType: "",
      },
      {
        image: casinoegame,
        title: "BETGAMES",
        type: "bg",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "BG",
        gameType: "LIVE",
        casinoType: "BG-LIVE-001",
      },
      {
        image: casinotable,
        title: "REDTIGER",
        type: "rt",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "LIVE",
        platForm: "RT",
      },
      {
        image: casinovirtual,
        title: "SPADEGAMING",
        type: "spade",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "live",
        platForm: "SPADE",
      },
      {
        image: casinolotto,
        title: "FC",
        type: "fc",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "LIVE",
        platForm: "FC",
      },
      {
        image: casinobingo,
        title: "JDB",
        type: "jdb",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "LIVE",
        platForm: "JDB",
      },
      {
        image: casinocrash,
        title: "YL",
        type: "yl",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "LIVE",
        platForm: "YL",
      },

      {
        image: casinogameshow,
        title: "DRAGONSOFT",
        type: "dragonsoft",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "LIVE",
        platForm: "DRAGONSOFT",
      },

      {
        image: "",
        title: "",
        type: "",
        key: "",
        gameTypeCheck: "",
        gameType: "",
        platForm: "",
      },
    ],
  },
  {
    id: 2,
    title: "Live",
    key: "live",
    titlebn: "লাইভ ক্যাসিনো",
    array: [
      {
        image: sexy,
        title: "SEXYBCRT",
        type: "sexy",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "SEXYBCRT",
        gameType: "LIVE",
        casinoType: "MX-LIVE-002",
      },
      {
        image: evo,
        title: "EVOLUTION",
        type: "evo",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-006",
        isLobbyTrue: true,
      },
      {
        image: ezugi,
        title: "Ezugi",
        type: "ezugi",
        key: "live",
        gameType: "egtGame",
        platForm: "Ezugi",
        gameTypeNew: "",
        casinoType: "",
      },
      {
        image: pp,
        title: "PRAGMATICPLAY",
        type: "pp",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "PP",
        gameType: "LIVE",
        casinoType: "PP-LIVE-001",
      },

      {
        image: pt,
        title: "PLAYTECH",
        type: "pt",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "PT",
      },
      {
        image: betgames,
        title: "BETGAMES",
        type: "bg",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "BG",
        gameType: "LIVE",
        casinoType: "BG-LIVE-001",
      },
      {
        image: royalgaming,
        title: "SPRIBE",
        type: "SPRIBE",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "SPRIBE",
      },
      {
        image: superspade,
        title: "SUPERSPADE",
        type: "SUPERSPADE",
        key: "live",
        gameType: "egtGame",
        platForm: "SUPERSPADE",
        gameTypeNew: "",
        casinoType: "",
      },

      {
        image: onetouch,
        title: "ONETOUCH",
        type: "ONETOUCH",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "ONETOUCH",
      },

      {
        image: bombaylive,
        title: "SPRIBE",
        type: "SPRIBE",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "SPRIBE",
      },
      {
        image: biggaming,
        title: "BIGGAMING",
        type: "biggaming",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "LIVE",
        platForm: "BG",
        casinoType: "",
      },

      {
        image: sv388,
        title: "SV388",
        type: "sv",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "SV388",
      },
    ],
  },
  {
    id: 2,
    title: "Slot",
    key: "slot",
    titlebn: "স্লট",
    array: [
      // {
      //   image: btg,
      //   title: "BTG",
      //   type: "btg",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "BTG",
      // },

      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "slot",
        // gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "JILI",
      },
      {
        image: pg,
        title: "PG",
        type: "pg",
        key: "slot",
        // gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PG",
      },
      {
        image: pp,
        title: "PP",
        type: "pp",
        key: "slot",
        // gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PP",
      },
      {
        image: jdb,
        title: "JDB",
        type: "jdb",
        key: "slot",
        // gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "JDB",
      },
      {
        image: fc,
        title: "FC",
        type: "fc",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "FC",
      },

      {
        image: sg,
        title: "SG",
        type: "spade",
        key: "slot",
        // gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "SPADE",
      },
      {
        image: rt,
        title: "RT",
        type: "rt",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "",
        platForm: "RT",
      },
      {
        image: fastspin,
        title: "FASTSPIN",
        type: "FastSpin",
        key: "live",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "FASTSPIN",
      },
      {
        image: pt,
        title: "PT",
        type: "pt",
        key: "slot",
        // gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PT",
      },
      {
        image: yesbingo,
        title: "Yellow Bat",
        type: "yesbingo",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "YESBINGO",
      },






      {
        image: km,
        title: "KINGMAKER",
        type: "kingmaker",
        key: "slot",
        gameType: "SLOT",
        platForm: "KINGMAKER",
      },
      
      
    
     
     
      

     
      // {
      //   image: evoplay,
      //   title: "EVOPLAY",
      //   type: "EVOPLAY",
      //   key: "slot",
      //   gameType: "egtGame",
      //   platForm: "EVO",
      //   gameTypeNew: "",
      //   casinoType: "LIVE CASINO",
      // },

      {
        image: dragonsoft,
        title: "DRAGONSOFT",
        type: "dragonsoft",
        key: "slot",
        // gameTypeCheck: "platForm",
        gameType: "LIVE",
        platForm: "DRAGONSOFT",
      },
      // {
      //   image: onetouch,
      //   title: "ONETOUCH",
      //   type: "ONETOUCH",
      //   key: "live",
      //   gameTypeCheck: "platForm",
      //   platForm: "ONETOUCH",
      // },

     

    

      // {
      //   image: ps,
      //   title: "PLAYSTAR",
      //   type: "PLAYSTAR",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      //   casinoType: "PP-SLOT-001",
      // },

      // {
      //   image: rich88,
      //   title: "RICH88",
      //   type: "RICH88",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      //   casinoType: "PP-SLOT-001",
      // },
      // {
      //   image: ka,
      //   title: "KA",
      //   type: "KA",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JDB",
      // },
      // {
      //   image: cq9,
      //   title: "CQ9",
      //   type: "CQ9",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "FC",
      //   casinoType: "FC-SLOT-001",
      // },
      {
        image: netent,
        title: "NETENT",
        type: "NETENT",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "NETENT",
        platForm: "",
        casinoType: "",
      },
      {
        image: nlcLogo,
        title: "NLC",
        type: "NLC",
        key: "live",
        gameTypeCheck: "platForm",
        platForm: "NLC",
      },
      //{
      //   image: worldmatch,
      //   title: "WorldMatch",
      //   type: "WorldMatch",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JDB",
      //   casinoType: "JDB-SLOT-001",
      // },

      // {
      //   image: FASTSPIN,
      //   title: "FastSpin",
      //   type: "FastSpin",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "FASTSPIN",
      // },
      // {
      //   image: joker,
      //   title: "Joker",
      //   type: "Joker",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "JDB",
      //   casinoType: "JDB-SLOT-002",
      // },
      // 
    ],
  },
  {
    id: 3,
    title: "Table",
    key: "table",
    titlebn: "টেবিল",
    array: [
      {
        image: km,
        title: "KM",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
      },

      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "JILI",
      },
      {
        image: jdb,
        title: "JDB",
        type: "jdb",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "JDB",
      },
      {
        image: rt,
        title: "REDTIGER",
        type: "rt",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "RT",
      },
      {
        image: netent,
        title: "NETENT",
        type: "NETENT",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "NETENT",
        platForm: "",
        casinoType: "",
      },
      // {
      //   image: png,
      //   title: "PNG",
      //   type: "PNG",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "JILI",
      //   casinoType: "JILI-TABLE-004",
      // },{
      //   image: rich88,
      //   title: "RICH88",
      //   type: "RICH88",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },

      // {
      //   image: ka,
      //   title: "KA",
      //   type: "KA",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "KINGMAKER",
      //   casinoType: "KINGMAKER-TABLE-004",

      // },{
      //   image: worldmatch,
      //   title: "WorldMatch",
      //   type: "WorldMatch",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "KINGMAKER",
      //   casinoType: "KINGMAKER-TABLE-005",
      // },{
      //   image: cq9,
      //   title: "CQ9",
      //   type: "CQ9",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "KINGMAKER",
      //   casinoType: "KINGMAKER-TABLE-006",
      // },{
      //   image: bpoker,
      //   title: "Bpoker",
      //   type: "bpoker",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "JILI",
      // },
      // {
      //   image: ludo,
      //   title: "LUDO",
      //   type: "ludo",
      //   key: "table",
      //   gameTypeCheck: "platForm",
      //   platForm: "KINGMAKER",
      //   gameType: "TABLE",
      //   casinoType: "KM-TABLE-060",
      // },
      {
        image: spribe,
        title: "SPRIBE",
        type: "SPRIBE",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "SPRIBE",
        casinoType: "SPRIBE-TABLE-001",
      },
      // {
      //   image: royalgaming,
      //   title: "SPRIBE",
      //   type: "SPRIBE",
      //   key: "TABLE",
      //   gameTypeCheck: "platForm",
      //   platForm: "SPRIBE",
      //   gameType: "TABLE",
      // },
      {
        image: pg,
        title: "PGSOFT",
        type: "pg",
        key: "TABLE",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PG",
        gameType: "TABLE",
      },
      // {
      //   image: bombaylive,
      //   title: "SPRIBE",
      //   type: "SPRIBE",
      //   key: "TABLE",
      //   gameTypeCheck: "platForm",
      //   platForm: "SPRIBE",
      //   gameType: "TABLE",
      // },
      
    ],
  },
  {
    id: 4,
    title: "Fishing",
    key: "fish",
    type: "fish",
    array: [
      {
        image: Jili,
        title: "JILI",
        type: "jili",
        key: "fishing",
        
        gameType: "FH",
        platForm: "JILI",
      },
      {
        image: fc,
        title: "FC",
        type: "fc",
        key: "fishing",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "FC",
      },
      // {
      //   image: ka,
      //   title: "KA",
      //   type: "KA",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "FC",
      //   casinoType:"FC-FISH-001"

      // },
      {
        image: jdb,
        title: "JDB",
        type: "jdb",
        key: "fishing",
        
        gameType: "FH",
        platForm: "JDB",
      },
      // {
      //   image: ps,
      //   title: "PLAYSTAR",
      //   type: "PLAYSTAR",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },{
      //   image: cq9,
      //   title: "CQ9",
      //   type: "CQ9",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-009",
      // },
      // {
      //   image: joker,
      //   title: "Joker",
      //   type: "Joker",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-010",
      // },
      // {
      //   image: p8,
      //   title: "P8",
      //   type: "P8",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-009",
      // },
      {
        image: yl,
        title: "YL",
        type: "yl",
        key: "fishing",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "YL",
      },
      {
        image: yesbingo,
        title: "Yes Bingo",
        type: "yesbingo",
        key: "fishing",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "YESBINGO",
      },
      {
        image: sg,
        title: "Spade",
        type: "spade",
        key: "fishing",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "SPADE",
      },
      // {
      //   image: fastspin,
      //   title: "FASTSPIN",
      //   type: "FastSpin",
      //   key: "fishing",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "FASTSPIN",
      // },
     
    ],
  },
  // {
  //   id: 4,
  //   title: "Crash",
  //   key: "crash",
  //   type: "crash",
  //   array: [
  //     {
  //       image: spribe,
  //       title: "SPRIBE",
  //       type: "crash",
  //       key: "crash",
  //       gameTypeCheck: "platForm",

  //       platForm: "SPRIBE",
  //     },
  //     {
  //       image: km,
  //       title: "KINGMAKER",
  //       type: "crash",
  //       key: "crash",
  //       gameTypeCheck: "platForm",

  //       platForm: "KINGMAKER",
  //     },
  //     {
  //       image: Jili,
  //       title: "JILI",
  //       type: "crash",
  //       key: "crash",
  //       gameTypeCheck: "platForm",

  //       platForm: "JILI",
  //     },
  //     {
  //       image: pp,
  //       title: "PP",
  //       type: "crash",
  //       key: "crash",
  //       gameTypeCheck: "platForm",

  //       platForm: "PP",
  //     },
  //   ],
  // },
  {
    id: 4,
    title: "Aviator",
    key: "aviator",
    type: "aviator",
    array: [
      {
        image: crashIcon,
        title: "Aviator",
        type: "aviator",
        key: "aviator",
        gameTypeCheck: "platForm",
        platForm: "SPRIBE",
        gameType: "EGAME",
        casinoType: "SPRIBE-EGAME-001",
      },
    ],
  },
  {
    id: 6,
    title: "Arcade",
    key: "arcade",
    titlebn: "স্পোর্টসবুক",
    array: [
      {
        image: iconawcme1sport,
        title: "E1sports",
        type: "e1sports",
        key: "esports",
        gameTypeCheck: "platForm",
        gameType: "ESPORTS",
        platForm: "E1SPORT",
        casinoType:"E1-ESPORTS-001"
      },
      // {
      //   image: rich88,
      //   title: "RICH88",
      //   type: "RICH88",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },{
      //   image: cq9,
      //   title: "CQ9",
      //   type: "CQ9",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-009",
      // },
      // {
      //   image: pp,
      //   title: "PP",
      //   type: "pp",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },
      // {
      //   image: fc,
      //   title: "FC",
      //   type: "fc",
      //   key: "fish",
      //   gameTypeCheck: "platForm",
      //   gameType: "EGAME",
      //   platForm: "FC",
      // },
      {
        image: yl,
        title: "YL",
        type: "yl",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "EGAME",
        platForm: "YL",
      },
      {
        image: sg,
        title: "Spade",
        type: "spade",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "EGAME",
        platForm: "SPADE",
      },
      // {
      //   image: ps,
      //   title: "PLAYSTAR",
      //   type: "PLAYSTAR",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "SLOT",
      //   platForm: "PP",
      // },
      // {
      //   image: ka,
      //   title: "KA",
      //   type: "KA",
      //   key: "slot",
      //   gameTypeCheck: "platForm",
      //   gameType: "FH",
      //   platForm: "JDB",
      //   casinoType: "JDB-FISH-011",
      // },
    ],
  },
  {
    id: 7,
    title: "Virtual",
    key: "virtual",
    titlebn: "খেলাধুলা",
    array: [
      {
        image: sabavrtual,
        title: "SABAVIRTUAL",
        type: "SABAVIRTUAL",
        key: "virtual",
        gameTypeCheck: "platForm",
        platForm: "SABA",
        gameType: "VIRTUAL",
        casinoType: "SABA-VIRTUAL-001",
      },
      
    ],
  },
];

export const eventSlider = [
  { id: 1, img: "/assets/images/fav1.jpg" },
  { id: 2, img: "/assets/images/fav1.jpg" },
  {
    id: 3,
    img: "/assets/images/fav1.jpg",
  },
  {
    id: 4,
    img: "/assets/images/fav4.jpg",
  },
];


export const validationRules = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/,
  newPass: /^[a-zA-Z0-9]{8,15}$/,
  newPassMessage:
    "Password must contain uppercase and lowercase characters, numbers and must be minimum 8 character long (without special character).",
  passwordMessage:
    "Password must contain uppercase and lowercase characters, numbers, special character and must be minimum 8 character long.",
  characters: /^[a-zA-Z_ ]*$/,
  numbers: /^[0-9]*$/,
  removeWhitespace: /^[a-zA-Z0-9]+$/,
  numberNew: /^[0-9]*$/,
  numberWithDot: /^\d*(\.\d{0,10})?$/,
};
export const preventMaxInput = (e) => {
  e.target.value = e.target.value.trimStart();
  e.target.value = e.target.value.replace(/  +/g, " ");
};
export const AmountArray = [
  { id: 1, amount: 100 },
  { id: 2, amount: 500 },
  { id: 3, amount: 1000 },
  { id: 4, amount: 5000 },
  { id: 5, amount: 1000 },
  { id:6, amount: 15000 },
  { id: 7, amount: 20000 },
  { id: 8, amount: 25000 },
];