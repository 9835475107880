import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import appdownloadicon from "../assets/images/header-appdownload-icon.svg";
import headerserviceicon from "../assets/images/header-service-icon.svg";
import { isEmpty } from "lodash";
import AuthContext from "../context/AuthContext";
import {
  MdArrowBackIosNew,
  MdKeyboardArrowLeft,
  MdOutlineSubject,
} from "react-icons/md";
import MenuSidebar from "./MenuSidebar";
import MenuSidebarNew from "./MenuSidebarNew";
import { HiMenuAlt3, HiOutlineRefresh } from "react-icons/hi";
import { useTranslation } from "react-i18next";
// import baji24app from "../assets/images/baji24.apk";
import FlagIconBn from "../assets/images/BD.png";
import LanguagePopup from "./LanguagePopup";
import h1 from "../assets/images/h1.png";
import h2 from "../assets/images/h2.png";
import h3 from "../assets/images/h3.png";
import h4 from "../assets/images/h4.png";
import h5 from "../assets/images/h5.png";
import Slider from "react-slick";
import welcomebn from "../assets/images/login-img.png";

const HeaderNew = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows:false
  };
  const {
    user,
    handleShowLanguage,
    showLanguage,
    lang,
    handleShowSidebar,
    showSidebar,
    amounutRefresh,
    refreshAmountLoader,
    userCoins,
    loginUser,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <img src={welcomebn}  className="d-logomain"></img>
      <div className="login-header-new forh-slider">
        <figure onClick={() => navigate("/")}>
          <img src={logo} alt=""  className="head-logo" />
        </figure>
        <Slider {...settings}>
          <div>
            <img src={h1} alt="icon-sponsor" class="spon-icon" />
          </div>
          <div>
            <img src={h2} alt="icon-sponsor" class="spon-icon" />
          </div>
          <div>
            <img src={h3} alt="icon-sponsor" class="spon-icon" />
          </div>
          <div>
            <img src={h4} alt="icon-sponsor" class="spon-icon" />
          </div>
          <div>
            <img src={h5} alt="icon-sponsor" class="spon-icon" />
          </div>
        </Slider>
        <div className="login-reg-wrapper">
          {isEmpty(user) ? (
            <figcaption>
              {" "}
              <Link to="/login" className="login-btn">
                {t("Login")}
              </Link>
              <Link to="https://baji24.info" className="signup-btn">
                {t("Sign_up")}
              </Link>
              <div
                className="language-select-div"
                onClick={() => handleShowLanguage()}
              >
                <img src={lang == "bn" ? FlagIconBn : FlagIconBn} alt="" />{" "}
              </div>
            </figcaption>
          ) : (
            <>
            
            <div className="h-amt">
              {!isEmpty(user) && (
                <>
                 <div className="d-flex align-items-center after-login">
          {refreshAmountLoader ? (
            <div class="loader-outer">
              <span
                class="loader-inner-icon"
                style={{ animationDelay: "0s" }}
              ></span>
              <span
                class="loader-inner-icon"
                style={{ animationDelay: ".1s" }}
              ></span>
              <span
                class="loader-inner-icon"
                style={{ animationDelay: ".2s" }}
              ></span>
              <span
                class="loader-inner-icon"
                style={{ animationDelay: ".3s" }}
              ></span>
              <span
                class="loader-inner-icon"
                style={{ animationDelay: ".4s" }}
              ></span>
              <span
                class="loader-inner-icon"
                style={{ animationDelay: ".5s" }}
              ></span>
              <span
                class="loader-inner-icon"
                style={{ animationDelay: ".6s" }}
              ></span>
              <span
                class="loader-inner-icon"
                style={{ animationDelay: ".7s" }}
              ></span>
            </div>
          ) : (
            <div>
              <span style={{color:`#ffc800`}}>{user?.user?.username}</span>
              <div className="d-flex align-items-center ">
                <strong className="header-font-size" style={{color:`#ffc800`}}>
                  USD <span>{userCoins?.balance?.toFixed(2)}</span>
                </strong>
                <strong className="header-font-size">
                  Exp{" "}
                  <span className="text-danger" style={{fontWeight:'700'}}>
                    ({userCoins?.exp?.toFixed(2)})
                  </span>
                </strong>
              </div>
            </div>
          )}
          <div onClick={amounutRefresh}>
            <HiOutlineRefresh
              size={20}
              className={refreshAmountLoader ? "refresh-icon" : ""}
            />
          </div>
        </div>
                </>
              )}
            </div>
            </>
          )}
        </div>

        {showSidebar && (
          <MenuSidebarNew
            cancelMenu={handleShowSidebar}
            showSidebar={showSidebar}
          />
        )}

        {showSidebar && (
          <div className="overlay" onClick={() => handleShowSidebar()}></div>
        )}

        {showLanguage && (
          <LanguagePopup
            cancelMenu={handleShowLanguage}
            showSidebar={showLanguage}
          />
        )}
        {showLanguage ? (
          <div className="overlay" onClick={() => handleShowLanguage()}></div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default HeaderNew;
