import React, { useContext,useEffect } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";

import iconMenu from "../assets/images/icon-set/inactive/icon-menu.png";
import iconMenuActive from "../assets/images/icon-set/active/icon-menu-active.png";
import iconCasino from "../assets/images/icon-set/inactive/icon-casino.png";
import iconCasinoActive from "../assets/images/icon-set/active/icon-casino-active.png";
import iconSlot from "../assets/images/icon-set/inactive/icon-slot.png";
import iconSlotActive from "../assets/images/icon-set/active/icon-slot-active.png";
import iconPromotion from "../assets/images/icon-set/inactive/icon-promotion.png";
import iconPromotionActive from "../assets/images/icon-set/active/icon-promotion-active.png";
import iconProfile from "../assets/images/icon-set/inactive/icon-profile.png";
import iconProfileActive from "../assets/images/icon-set/active/icon-profile-active.png";


const FooterNew = () => {
  const { user, lang, setEn, setBr,handleShowSidebar  } = useContext(AuthContext);

  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="footer-new">
      <ul>
        
      <li onClick={() => handleShowSidebar()} className="">
              <div className="payment-box footer-payment-box ">
                <figure>
                  <img src={iconMenu} />
                </figure>
                <span>{t("Menu")}</span>
              </div>
            </li>
            
            <li onClick={() => navigate("/casino")} className="">
              <div className="payment-box footer-payment-box ">
                <figure>
                  <img src={iconCasino} alt="" />
                </figure>
                <span>
                  {lang == "bn"
                    ? "Casino"
                    : "Casino"}
                </span>
              </div>
            </li>
            <li onClick={() => navigate("/casino?header=slot")} className="">
              <div className="payment-box footer-payment-box ">
                <figure>
                  <img src={iconSlot} alt="" />
                </figure>
                <span>
                {lang == "bn"
                    ? "Slots"
                    : "Slots"}
                </span>
              </div>
            </li>
            {/* <li onClick={() => navigate("/promotions")} className="">
              <div className="payment-box footer-payment-box ">
                <figure>
                  <img src={iconPromotion} alt="" />
                </figure>
                <span>
                {lang == "bn"
                    ? "Promotions"
                    : "Promotions"}
                </span>
              </div>
            </li> */}
             {/* {!isEmpty(user) && ( */}
            <li onClick={() => isEmpty(user) ?navigate("/login"):navigate("/myaccount")} className="">
              <div className="payment-box footer-payment-box ">
                <figure>
                  <img src={iconProfile} alt="" />
                </figure>
                <span>{t("Acount")}</span>
                
              </div>
            </li>
            {/* )} */}
      </ul>
      
    </div>
  );
};

export default FooterNew;
