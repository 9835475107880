import React, { useEffect, useRef, useState } from "react";
import "./SpinWheel.css";
import spinAudio from './spin-wheel-1.mp3';
import arrow from './arrow-icon.png';

const SpinWheel = () => {
    const canvasRef = useRef(null);
    const [angVel, setAngVel] = useState(0);
    const [ang, setAng] = useState(0);
    const [spinning, setSpinning] = useState(false);
    const [soundEnabled, setSoundEnabled] = useState(false);
    const [winner, setWinner] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [winnerList, setWinnerList] = useState([]);
    const [predefinedWinner, setPredefinedWinner] = useState(10); // Default to 10
    const [spinSpeed, setSpinSpeed] = useState(0.60); // Fixed speed for controlled spin
    const [totalSpin, setTotalSpin] = useState(0); // Fixed speed for controlled spin

    const spinSound = useRef(new Audio(spinAudio));
    
    const sectors = [
        { color: "#E2CD20", text: "#000", label: "10" },
        { color: "#78D1EB", text: "#000", label: "20" },
        { color: "#E27B20", text: "#000", label: "30" },
        { color: "#78D1EB", text: "#000", label: "40" },
        { color: "#E2CD20", text: "#000", label: "50" },
        { color: "#E27B20", text: "#000", label: "60" },
        { color: "#E2CD20", text: "#000", label: "70" },
        { color: "#78D1EB", text: "#000", label: "80" },
        { color: "#E27B20", text: "#000", label: "90" },
        { color: "#78D1EB", text: "#000", label: "100" },
        { color: "#E2CD20", text: "#000", label: "200" },
        { color: "#E27B20", text: "#000", label: "300" },
    ];

    // ** Control Variables (Modify Here) **

    const drawWheel = () => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext("2d");
        const arc = (2 * Math.PI) / sectors.length;
        const rad = canvas.width / 2;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        sectors.forEach((sector, i) => {
            const angle = arc * i;
            ctx.save();
            ctx.beginPath();
            ctx.fillStyle = sector.color;
            ctx.moveTo(rad, rad);
            ctx.arc(rad, rad, rad, angle, angle + arc);
            ctx.lineTo(rad, rad);
            ctx.fill();

            ctx.translate(rad, rad);
            ctx.rotate(angle + arc / 2);
            ctx.textAlign = "right";
            ctx.fillStyle = sector.text;
            ctx.font = "bold 25px 'Impact','Comic Sans MS', cursive, sans-serif";
            ctx.fillText(sector.label, rad - 20, 10);
            ctx.restore();
        });
        rotateWheel(); 
    };

    const rotateWheel = () => {
        const canvas = canvasRef.current;
        if (canvas) {
            canvas.style.transform = `rotate(${ang - Math.PI / 2}rad)`;
        }
    };

    // ** Control Variables (Modify Here) **
    
    const spinWheel = () => {
        if (!spinning) {
            // **Step 1: Start with a random velocity**
            let speed = Math.random() * (0.45 - 0.25) + spinSpeed; 
            setAngVel(speed);
            console.log(" ==== Speed ==== : ", speed);
            setSpinning(true);
            setWinner(null);
            
            // // Determine the target angle for the winning sector
            // **Step 1: Predefine the Winning Sector Index**
            const PI = Math.PI;
            const TAU = 2 * PI;
            const targetAngle = (TAU - (predefinedWinner / sectors.length) * TAU)+0.25;
            console.log("Target Angle  : ", targetAngle);
            console.log(" angVel  : ", angVel);
            console.log("ang  : ", ang);
            
            // Gradually slow down to the target angle
            const spinDuration = 6000; // Spin for 6 seconds
            setTimeout(() => {
                //console.log(" angVel  : ", angVel);
                //setAngVel(0.0566666); // Slow down
                console.log(" Slowing down... ");
            }, spinDuration);
        
            setTimeout(() => {
                setAng(targetAngle); // Stop at the exact angle
                setAngVel(0);
                rotateWheel();
                console.log("Stopped at:", targetAngle);
                setSpinning(false);
                onSpinEnd(predefinedWinner-1);
            }, spinDuration + 2000); // Add extra time to give it a nice stop
        }
    
        if (soundEnabled) {
            spinSound.current.currentTime = 2; // Restart from the beginning
            spinSound.current.play().catch((error) => console.error("Audio play error:", error));
        }
    }

    const onSpinEnd = (index) => {
        // let cal = Math.floor(sectors.length - (ang / (2 * Math.PI)) * sectors.length) % sectors.length;
        let cal =  index;
        console.log(" Cal : ",cal);
        const finalSector = sectors[cal];
        setWinner(finalSector.label);
        setWinnerList((prev) => {
            const updatedList = [...prev, finalSector.label];
            localStorage.setItem('winner', JSON.stringify(updatedList)); // Save to localStorage
            return updatedList;
        });
        setTotalSpin(parseInt(totalSpin)-1);
        // showSuccessMessage('Congratulations!', `Woop! You won ${finalSector.label}`);
        showSuccessMessage("Better Luck for next time");

        spinSound.current.pause();
    }

    useEffect(() => {
        drawWheel();
        localStorage.clear();
        const PI = Math.PI;
        const TAU = 2 * PI;
        const targetAngle = (TAU - (predefinedWinner / sectors.length) * TAU)+0.25;
        setAng(targetAngle)
    }, []);

    useEffect(() => {
        if (!spinning) return;
        const frame = () => {
            setAng((prevAng) => (prevAng + angVel) % (2 * Math.PI));
            setAngVel((prevVel) => prevVel * 0.992); 
            if (angVel < 0.002) {
                setAngVel(0);
                setSpinning(false);
                onSpinEnd();
            }
            rotateWheel();
        };
        const animation = requestAnimationFrame(frame);
        return () => cancelAnimationFrame(animation);
    }, [ang, angVel, spinning]);

    const showSuccessMessage = (title, message) => {
        setModalTitle(title);
        setModalMessage(message);
        if (title === "ALL RESULTS") {
            const winners = JSON.parse(localStorage.getItem('winner')) || [];
            setWinnerList(winners);
        }
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const openPopUp = () => {
        showSuccessMessage('ALL RESULTS', 'This section shows all the results selected during the current visit.');
    };

    useEffect(() => {
        spinSound.current.muted = !soundEnabled; // Keep it muted initially
        spinSound.current.preload = "auto"; // Preload to avoid delays
    }, [soundEnabled]);


    return (
        <div className="container-main">
            <div style={{position:"relative"}}>
                <span className="down-arrow">
                    <img src={arrow} alt="Arrow" style={{ width: 43, height: 43 }} />
                </span>
                <div id="spin_the_wheel">
                    <canvas ref={canvasRef} width={400} height={400} />
                    <div id="spin" ></div>
                </div>
            </div>
            <div className="sound-icon-container">
                <button id="sound-toggle" onClick={() => setSoundEnabled((prev) => !prev)}>
                    {soundEnabled ? "🔊" : "🔇"}
                </button>
            </div>
            <div className="play-btn-container">
                <button id="play" className="btn" onClick={ ()=> { 
                                                                    console.log("totalSpin : " ,totalSpin );
                                                                    if (totalSpin > 0) {
                                                                        spinWheel();
                                                                    } else {
                                                                        showSuccessMessage('WARNING ALERT', "You don't have sufficient spin");
                                                                    }
                                                              }} disabled={spinning}>▶️ Play</button>
            </div>
            <div className="trophy-container" onClick={openPopUp}>
                <button id="trophy-button">🏆</button>
            </div>

            {showModal && (
                <div id="success-modal" className="modal">
                    <div className="modal-content won-style">
                        <span className="close-button" onClick={closeModal}>&times;</span>
                        <h2 id="modal-title">{modalTitle}</h2>
                        <p id="modal-message">{modalMessage}</p>
                        {modalTitle === "ALL RESULTS" && winnerList.length > 0 ? (
                            <ul id="winner-list">
                                {winnerList.map((win, index) => (
                                    <li key={index}>{index + 1}: {win}</li>
                                ))}
                            </ul>
                        ) : null}
                        <button className="won-button" onClick={closeModal}>OK</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SpinWheel;
