import React, { useContext } from "react";
import { Link, useNavigation } from "react-router-dom";
import { SlotData } from "../../Utils/constants";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/AuthContext";
import { isEmpty } from "lodash";
import { ProviderSliderData } from "../../Utils/constants";
import Slider from "react-slick";

const ProviderSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
  };
  const { t } = useTranslation();
  const { lang, user, launchEGTCasino, launchCasino,withoutLogin } = useContext(AuthContext);
  const navigate = useNavigation();
  return (
    <>
      <div className="common-slider">
              <div className="heading-block">
                <h5>{t("Provider")}</h5>
                {/* <Link to="/">{t("See_All")}</Link> */}
              </div>
      
              <div className="common-slider-box provider-slider">
                
              <Slider {...settings}>
                  {ProviderSliderData.map((item) => {
                    return (
                      <div
                        onClick={() => {
                            if (!isEmpty(user)) {
                              if (item?.gameTypeCheck == "platForm") {
                                launchCasino({
                                  platForm: item?.platForm,
                                  gameType: item?.gameType,
                                  casinoType: item?.casinoType,
                                });
                              } else {
                                if (
                                  item?.platForm !== "" &&
                                  item?.gameType !== "" &&
                                  item?.casinoType !== ""
                                ) {
                                  launchCasino({
                                    platForm: item?.platForm,
                                    gameType: item?.gameType,
                                    casinoType: item?.casinoType,
                                  });
                                }
                              }
                            } else {
                              withoutLogin()
                            }
                        }}
                        className="slider-items"
                      >
                        <figure className="mb-0">
                          <img src={item?.icons} alt="" />
                        </figure>
                        <figcaption>
                          <h6>{lang == "bn" ? item?.titlebn : item?.title}</h6>
                          {/* <span>Daraz</span> */}
                        </figcaption>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
    </>
  );
};

export default ProviderSlider;
