import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import whatsapp from "../assets/images/whatsapp.png";
import telegram from "../assets/images/telegram.png";
import livechat from "../assets/images/live-chat.png";
import spinIcon from "../assets/images/wofSpin.gif";
import Draggable from "react-draggable";
import Close from "../assets/images/icon-close.webp";
import LeftIcon from "../assets/images/icon-title.webp";
import Up from "../assets/images/Casino/7up.webp";
import Andhar from "../assets/images/Casino/andharBahar.webp";
import Fortune from "../assets/images/Casino/fortuneFlip.webp";
import Rocket from "../assets/images/Casino/rocket.webp";
import Lucky from "../assets/images/Casino/luckyBeer.webp";
import { isEmpty } from "lodash";
import { MdSupervisorAccount } from "react-icons/md";
import { isBrowser } from "react-device-detect";

import "../Spin.css";
const Footer = () => {
  const [showSpin, setShowSpin] = useState(true);
  const location = useLocation();
  const { user, showSidebar, active, setFooterLink , casinoUrl,
    setCasinoUrl,launchCasino} = useContext(AuthContext);
  const navigate = useNavigate();
  const [casinoModel, setCasinoModel] = useState(false);
  const [startX, setStartX] = useState(null);
  const [startY, setStartY] = useState(null);
  const [touch, setTouch] = useState("");
  const handleTouchStart = (event) => {
    const touch = event?.touches[0];

    setStartX(touch?.clientX);
    setStartY(touch?.clientY);
  };

  const handleTouchEnd = (event) => {
    const touch = event.changedTouches[0];
    const endX = touch.clientX;
    const endY = touch.clientY;
    const distance = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);
    if (distance < 5) {
      setGameFooter(true);
    }
  };
  const handleTouchEnd2 = (event) => {
    const touch = event.changedTouches[0];
    const endX = touch.clientX;
    const endY = touch.clientY;
    const distance = Math.sqrt((endX - startX) ** 2 + (endY - startY) ** 2);
    if (distance < 5) {
      setShowSpin(true);
      navigate("/spin-wheel")
    }
  };
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRef = useRef(null);
  const handleResize = () => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current?.offsetHeight);
    }
  };
  useEffect(() => {
    // Get the initial height when the component mounts
    handleResize();

    // Add the resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [casinoModel]);
  let drag = {
    top: -600,
    left: -50,
    right: 250,
    bottom: 25,
  };
  let drag2 = {
    top: -600,
    left: -250,
    right: 50,
    bottom: 25,
  };
  

  const [scrollPosition, setSrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setSrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [icon, setIcon] = useState({});

  const [gameFooter, setGameFooter] = useState(false);

  const getData = async () => {
    let hostname = window.location.hostname;
    hostname = hostname.replace(/^www\./, "");
    hostname = hostname.replace(/^ag\./, "");
    const { data } = await apiGet(apiPath?.getIcon, {
      website: "baji24.com"
    });
    if (data?.success) {
      setIcon(data?.results);
    }
  };
  useEffect(() => {
    getData();
  }, []);

 
  const games = [
    {
      id: 1,
      name: "Fortune Flip",
      coins: 167,
      image: Fortune,
      request: {
        platForm: "PP",
        gameType: "SLOT",
        casinoType: "PP-SLOT-217",
        check: true,
      },
    },
    {
      id: 2,
      name: "Andar Bahar",
      coins: 123,
      image: Andhar,
      request: {
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-032",
        check: true,
      },
    },
    {
      id: 3,
      name: "7up7down",
      coins: 145,
      image: Up,
      request: {
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-028",
        check: true,
      },
    },
    {
      id: 4,
      name: "Rocket",
      coins: 145,
      image: Rocket,
      request: {
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-041",
        check: true,
      },
    },
    {
      id: 5,
      name: "Beer",
      coins: 145,
      image: Lucky,
      request: {
        platForm: "PT",
        gameType: "SLOT",
        casinoType: "PT-SLOT-216",
        check: true,
      },
    },
  ];
  console.log("s",showSpin)
  return (
    <>
    <div className="footer">
    {/* {location?.pathname == "/" && !showSidebar && active == "sports" && (
          
          icon?.whatsappContent!== "" || icon?.telegramContent!== "" || icon?.livechatContent!== "" ?
          <div className="betbtn1">
            {icon?.whatsappContent && icon?.whatsappContent !== "" && (
              <div
                onClick={() => {
                  window.open("https://wa.me/"+icon?.whatsappContent, "_self");
                }}
              >
                <img
                  src={icon?.whatsappIcon?process.env.REACT_APP_API_BASE_URL + icon?.whatsappIcon:whatsapp}
                  alt=""
                />
              </div>
            )}
            {icon?.telegramContent && icon?.telegramContent !== "" && (
              <div
                onClick={() => {
                  
                  window.open("https://t.me/"+icon?.telegramContent, "_self");
                }}>
                <img
                  src={icon?.telegramIcon?process.env.REACT_APP_API_BASE_URL + icon?.telegramIcon:telegram}
                  alt=""
                />
              </div>
            )}{" "}
            {icon?.livechatContent && icon?.livechatContent !== "" && location?.pathname == "/" && (
              <div
                onClick={() => {
                  window.open("https://wa.me/"+icon?.livechatContent, "_self");
                }}
              >
                <img
                  src={icon?.livechatIcon?process.env.REACT_APP_API_BASE_URL + icon?.livechatIcon:livechat}
                  alt=""
                />
              </div>
            )}
           
          
          </div>
          :""
          
    )} */}
     {gameFooter && (
        <div className="lobby slide-up">
        <div className="gmnheader">
          {/* <div
            className="header__img d-flex justify-content-between align-items-center"
            style={{ height: "9vw" }}
          >
            <div style={{ fontSize: "15px", marginLeft: "10px" }}>
              Lucky09
            </div>
            <span className="header__number" data-v-302cf84b="">
              2
            </span>
          </div> */}
          <div className="position-relative w-100">
            {" "}
            <img style={{ width: "45%" }} src={LeftIcon} />
            <span className="header__number" data-v-302cf84b="">
              5
            </span>
          </div>

          <div className="header__close" data-v-302cf84b="">
            <img
              className="icon-promote"
              src={Close}
              onClick={() => {
                setGameFooter(false);
                setCasinoUrl("");
              }}
              alt=""
            />
          </div>
        </div>
        <main
          class={
            !isEmpty(casinoUrl)
              ? "game-grid-new slide-left"
              : "game-grid slide-left"
          }
        >
          {console.log("casinoUrl",casinoUrl)}
          {!isEmpty(casinoUrl) ? (
            <div className="casino-play-inner">
              <iframe
                style={{ minHeight: "100%" }}
                className="w-100"
                src={casinoUrl}
              />
            </div>
          ) : (
            games.map((game, index) => (
              <div
                id={game?.id}
                onTouchStart={(e) => setTouch(e?.target?.id)}
                onTouchEnd={() => setTouch("")}
                onClick={() => launchCasino(game?.request)}
                class={`game-card-casino slide-left ${
                  touch == game?.id ? "game-card-casino-new slide-left" : ""
                }`}
              >
                <div id={game?.id} class="coin-info">
                  <MdSupervisorAccount />
                  <span id={game?.id} class="coin-count">
                    167
                  </span>
                </div>
                <img
                  id={game?.id}
                  src={game?.image}
                  alt="Fortune Flip"
                  class="game-image"
                />
                <div id={game?.id} class="game-info">
                  <h2 id={game?.id} class="game-title">
                    {game?.name}
                  </h2>
                </div>
              </div>
            ))
          )}
        </main>
      </div>
      )}
      <Draggable
                  bounds={drag}
                  onStart={handleTouchStart}
                  onStop={handleTouchEnd}
                >
                  <div
                    onClick={() => {
                      if (!isEmpty(user)) {
                        setGameFooter(true);
                      } else {
                        navigate("/login");
                      }
                    }}
                    style={{ position: isBrowser ? "absolute" : "fixed" }}
                    className="betbtn1"
                  >
                    <img src={
                  "../assets/images/entrance.svg"
                } alt="" />
                  </div>
                </Draggable>
                <Draggable
                  bounds={drag2}
                  onStart={handleTouchStart}
                  onStop={handleTouchEnd2}
                >
        {showSpin &&
      <div className="hot-game float-banner-right">
        <a className="close" onClick={()=>setShowSpin(false)}>✖</a>
        <img src={spinIcon} alt="" onClick={() =>  
          // isEmpty(user)?navigate("/login"): 
          // navigate("/spin-wheel")
          alert("comming soon")}
          />
      </div>
    }
     </Draggable>
      </div> 
       
    </>
  );
};

export default Footer;
